@import url("./styles/basscss.min.css");
@import url("./styles/Colors.css");
@import url("./styles/Type.css");

html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
p,
input {
  padding: 0px;
  margin: 0px;
}

::-webkit-scrollbar {
    display: none;
}

canvas {
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); /* mobile webkit */
}

/* radio button */
form.radioToggleContainer {
  background: #f5f5f5;
  border-radius: 48px;
  box-shadow: 0px 24px 56px rgba(0, 0, 0, 0.2);
}

input[type="radio"].toggle {
  display: none;
}

input[type="radio"].toggle + i {
  background: transparent;
  border-radius: 50%;
  padding: 8px 9px;
  opacity: 0.2;
  user-select: none;
  cursor: pointer;
  box-shadow: none;

  transition: all 0.2s ease-out;
}

input[type="radio"].toggle:checked + i {
  color: black;
  background: white;
  opacity: 1 !important;
  box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.2);
}

input[type="radio"].toggle:hover + i {
  /*color: blue;*/
  opacity: 0.4;
}

/*input[type="radio"].toggle-left + label {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

input[type="radio"].toggle-right + label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}*/

select {
  font: inherit;
  margin: 0;
  box-sizing: border-box;
  appearance: none;
}

label.select {
  position: relative;
}

label.select:after {
  content: "arrow_drop_down";
  font: 32px "Material Icons";
  opacity: 0.6;
  right: 8px;
  top: -8px;
  position: absolute;
  pointer-events: none;
}

#scene {
  position: absolute;
  top: 0;
}

#ThreeCanvas:hover {
  cursor: grab;
}
#ThreeCanvas:active {
  cursor: grabbing;
}

.gutters {
  margin: 2rem;
}

.no-events {
  pointer-events: none;
}

.pointer {
  cursor: pointer;
}

.hover-hide {
  opacity: 1;
  cursor: pointer;
  transition: opacity 0.25s var(--emo-out);
}

.hover-hide:hover {
  opacity: 0;
}

.hover {
  opacity: 1;
  cursor: pointer;
  transition: opacity 0.25s var(--emo-out);
}

.hover:hover {
  opacity: 0.6 !important;
}

.hover-bg {
  cursor: pointer;
  color: white;
  background: black;

  transition-property: background-color, color;
  transition-duration: .25s;
  transition-timing-function: ease-out;
}

.hover-bg:hover {
  background: red;
  color: black;
}

.border-2 {
  border-style: solid;
  border-width: 2px;
}

.exclusion {
  color: white !important;
  mix-blend-mode: exclusion;
}

.isolation {
  isolation: isolate;
}

.invisible {
  visibility: hidden;
}

.invert {
  filter: invert();
}

.centerX {
  transform: translateX(-50%);
  left: 50%;
}

.centerY {
  transform: translateY(-50%);
  top: 50%;
}

.centerAll {
  transform: translateX(-50%) translateY(-50%);
  left: 50%;
  top: 50%;
}

.squared {
  border-radius: 0px;
}

.rounded {
  border-radius: 16px;
}

.row {
  padding: 16px 0px;
}

.row p {
  font-size: 22px;
  font-weight: bold;
}

.vcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.full {
  width: 100%;
  height: 100%;
}

.full-viewport {
  width: 100vw;
  height: 100vh;
}

.full-viewport-width {
  width: 100vw;
}

.full-viewport-height {
  height: 100vh;
}

.full-height {
  height: 100%;
}

.full-height-max {
  max-height: 100%;
}

.full-width {
  width: 100%;
}

a {
  color: #000;
}

#stats {
  position: fixed;
  top: 0px;
  right: 0px;
}

.small-type {
  color: rgba(0, 0, 0, 0.5);
}

.white {
  color: white;
}

.material-icons {
  vertical-align: middle;
  /*user-select: none;*/
}

i.micro {
  font-size: 14px;
  font-weight: normal;
}

i.small {
  font-size: 24px;
  font-weight: normal;
}

i.medium {
  font-size: 32px;
  font-weight: normal;
}

i.large {
  font-size: 48px;
  font-weight: normal;
}

i.flip {
  transform: rotate(180deg);
}

i.rotate90 {
  transform: rotate(90deg);
}

.flip {
  transform: scaleX(-1);
}

button,
.button {
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

button:focus, .button:focus, input:focus, select:focus {
  outline: none;
}

.circleButton {
  display: inline-block;
  border-radius: 50%;
}

.buttonLabel {
  vertical-align: middle;
  padding-left: 8px;
  text-transform: uppercase;
  font-weight: bold;
}

input.hiddenInput {
  /*position: absolute;*/
  font-size: 16px; /* crazy hack so ios doesn't zoom in on focus */
  box-sizing: border-box;
  width: calc(100% - 120px);
  height: 96px;
  opacity: 0;
  caret-color: transparent;
  background: transparent;
  border: transparent;
  color: transparent;
  transform: translateY(-128px);
}

.mt5 {
  margin-top: 6rem;
}

.hidden {
  position: absolute;
  top: -99999px;
}

.blink {
  animation-name: blink;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  opacity: 1;
}

.shadowSmall {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.shadow {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}

.shadowLarge {
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.2);
}

.shadowHuge {
  box-shadow: 0px 32px 64px rgba(0, 0, 0, 0.2);
}

.shadowLarge-filter {
  filter: drop-shadow(0px 12px 24px rgba(0, 0, 0, 0.2));
}

.shadowHuge-filter {
  filter: drop-shadow(0px 32px 64px rgba(0, 0, 0, 0.2));
}

.textShadowSmall {
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.textShadow {
  text-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}

.textShadowLarge {
  text-shadow: 0px 12px 24px rgba(0, 0, 0, 0.2);
}

.textShadowHuge {
  text-shadow: 0px 32px 64px rgba(0, 0, 0, 0.2);
}

.mapButton {
  transition: all 300ms var(--emo-out);
}

.zn1 {
  z-index: -1
}

.mb-r, .mb4-r {
  margin-bottom: 8rem;
}

.mb3-r {
  margin-bottom: 3rem;
}

.mb2-r {
  margin-bottom: 2rem;
}

.min-width-4 {
  min-width: 50rem;
}

.footer {
  transform: scale(1);
  font-size: .85rem;
}

.scale-80 {
  transform: scale(.8);
}

select {
  /*background: yellow !important;*/
}

select option {
  /*background: red !important;*/
  /*color: white*/
}
select option:checked {
  box-shadow: 0 0 10px 100px white inset;
  color: white;
}

@keyframes blink {
  40% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.container {
  width: 70vw;
}

/* xs */
@media (max-width: 40em) {
  .row {
    padding: 8px 0px;
  }

  .container {
    width: 100vw;
  }

  .mb-r, .mb4-r {
    margin-bottom: 4rem;
  }

  .mb3-r {
    margin-bottom: 1rem;
  }

  .mb2-r {
    margin-bottom: .5rem;
  }

  .gutters {
    margin: 1rem;
  }

  .footer {
    transform: scale(.85);
    font-size: .85rem;
  }

}

/* sm */
@media (min-width: 40em) and (max-width: 52em) {

}

/* md */
@media (min-width: 52em) and (max-width: 64em) {

}

/* lg */
@media (min-width: 64em) and (max-width: 76em) {
}